import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import SelectList from './SelectList';
import { Content, Wrapper } from './styles';

interface OrientacoesProps {
  medicamentos: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaMedicamentosFhir: React.FC<OrientacoesProps> = ({
  medicamentos,
  passo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: medicamentos, passoExecutado: passo });

  const [
    { medicamentosStatus },
    ,
    { updateMedicamentosStatus },
  ] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map(
      (medicamento, index) => {
        const option = {
          key: `${index}`,
          title: medicamento.titulo,
          detail: medicamento.descricao,
          categoria: medicamento.categoria,
          value: medicamento.id,
          edited: medicamento.editado,
          pedido: medicamento.pedido,
        } as OptionIntegracao;

        return toOptionIntegracao(option);
      },
    );
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback((opts: OptionIntegracao[]) => {
    setSelectedOptions(opts);
  }, []);

  useEffect(() => {
    updateMedicamentosStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions) {
      changeIntegrationStatus(selectedOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Wrapper>
      <Content>
        <SelectList
          options={options}
          generalStatus={passo.executado ? 'disabled' : medicamentosStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaMedicamentosFhir;
